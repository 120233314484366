import React from 'react';
import {NavLink, useLocation, matchPath, useHistory, generatePath } from 'react-router-dom';
import { WhiteLogoIcon, AccountCircleIcon, KeyboardLeftArrowIcon, KeyboardRightArrowIcon } from 'components/icons/icons';
import { IconLinkButton, IconButton } from 'components/buttons/buttons';
import UserMenu from 'components/userMenu/userMenu';
import * as Links from 'utils/configuration/links';
import SkeletonWithDelay from 'components/skeletonWithDelay/skeletonWithDelay';
import { SUBSCRIPTIONS_SELECTED_PATH, REPORT_SELECTED_PATH, PAGE_SELECTED_PATH,  } from 'utils/configuration/links';
import { AuthContext } from 'contexts/authentication/authContext';

import style from './header.module.scss';

const LastVisitedReportKey = "last-visited-report";

export default function Header({ signedIn, user, reports, subscriptions }) {
  const [scrollX, setscrollX] = React.useState(0);
  const [scrollEnd, setscrollEnd] = React.useState(false);
  const [showScroll, setShowScroll] = React.useState(false);
  const [scrollEle, setScrollEle] = React.useState(null);

  const location = useLocation();
  const history = useHistory();
  const { organizationAlias } = React.useContext(AuthContext);

  const {data: reportsData, finished: reportsFinished} = reports;

  const setReport = (reportId, match) => {
    if(!reportId) {
      if(localStorage.getItem(LastVisitedReportKey)){
        setReport(localStorage.getItem(LastVisitedReportKey), match);       
      } else {
        setReport(reportsData[0].id, match);  
      }     
    } else { 
      localStorage.setItem(LastVisitedReportKey, reportId);
      if(match && reportId !== match.params.reportId) {
        history.push({
          pathname: generatePath(`/${organizationAlias}/${REPORT_SELECTED_PATH}`, {subscriptionId: match.params.subscriptionId, reportId: localStorage.getItem(LastVisitedReportKey)}),
          search: window.location.search
        });
      };
    };
  };

  const match = matchPath(location.pathname, {
    path: `/${organizationAlias}/${SUBSCRIPTIONS_SELECTED_PATH}`,
    exact: true,
    strict: false
  }) || matchPath(location.pathname, {
    path: `/${organizationAlias}/${REPORT_SELECTED_PATH}`,
    exact: true,
    strict: false
  }) || matchPath(location.pathname, {
    path: `/${organizationAlias}/${PAGE_SELECTED_PATH}`,
    exact: true,
    strict: false
  });

  if(reportsFinished && reportsData.length > 0) {
    if(match && match.params.reportId) {
      const reportToFind = reportsData.find(x => x.id === match.params.reportId);
      setReport(reportToFind?.id, match);
    } else {
      const lastVisitedReportId = localStorage.getItem(LastVisitedReportKey);
      const lastVisitedReport = reportsData.find(x => lastVisitedReportId && x.id === lastVisitedReportId);
      if(!lastVisitedReport) {
        localStorage.removeItem(LastVisitedReportKey);
        setReport(reportsData[0].id, match);
       } else {
        setReport(lastVisitedReportId, match);
      };
    };
  };

  const selectedSubscriptionId = match?.params.subscriptionId;
  const selectedReportId = match?.params.reportId;
  
  const topNav = document.getElementById('topNav');

  const scrollRef = React.useCallback(node => {
    if (node !== null) {
      if(node.offsetWidth < node.scrollWidth){
          setShowScroll(true);
        } else {
          setShowScroll(false);
        }
      setScrollEle(node);
    } else {
      setScrollEle(topNav)
    }
  }, [topNav]);

  const checkScrollEnd = () =>{
    if (
      Math.floor(scrollEle?.scrollWidth - scrollEle?.scrollLeft) <= scrollEle?.offsetWidth
    ) {
      setscrollEnd(true);
    } else {
      setscrollEnd(false);
    }
  }

  const slide = (shift) => {
    scrollEle.scrollLeft += shift;
    setscrollX(scrollX + shift);
    checkScrollEnd();
  };

  window.addEventListener('resize', (event) => {
    if(topNav?.offsetWidth < topNav?.scrollWidth){
      setShowScroll(true)
    } else {
      setShowScroll(false);
    }
    checkScrollEnd();
  })

  return (
    <header className={style.headerContainer}>
      <div className={style.logoContainer}>
        <IconLinkButton
          icon={<WhiteLogoIcon className={style.logoHW}/>}
          href={`${Links.MYHEALTHWISE}/${organizationAlias}/applications${window.location.search}`} 
          ariaLabel="Open My Healthwise landing page"
          className={style.logo}
        />    
      </div>
      {scrollEle?.scrollLeft > 0 && showScroll &&
      <IconButton tabindex="-1" className={style.lbutton} handleClick={() => slide(-200)}>
        <KeyboardLeftArrowIcon />
      </IconButton>}
      <div className={style.tabsContainer} >
        {reportsFinished 
          ? (<nav aria-label="main" className={style.navBarContainer}>
              {reportsData.length > 0
              ? (<ul id="topNav" ref={scrollRef} className={style.navBar}>
                {reportsData.map(tab => {
                  const isSelected = selectedReportId === tab.id;
                  const currentTab = document.getElementById(tab.id)  
                  return (
                    <li id={tab.id} disabled={!selectedSubscriptionId} className={`${style.navTab} ${isSelected ? style.selectedReport : ''}`} key={tab.id}>
                      <NavLink 
                        onClick={()=>{
                          scrollEle.scrollLeft = currentTab.getBoundingClientRect().left;
                          currentTab.scrollIntoView();
                          checkScrollEnd();
                        }} 
                        disabled={!selectedSubscriptionId} activeClassName={style.active} className={!selectedSubscriptionId ? style.disabledLink : ''} 
                        to={{
                          pathname: selectedSubscriptionId ? generatePath(`/${organizationAlias}/${REPORT_SELECTED_PATH}`, {subscriptionId: selectedSubscriptionId, reportId: tab.id}) : '/pending',
                          search: window.location.search
                        }}>
                        <span>{tab.name}</span>
                      </NavLink>
                    </li>
                  );
                })}
              </ul>)
              : <div className={style.noData}>No reports available</div>
              }
            </nav>)
          : (<SkeletonWithDelay className={style.navTab} containerClassName={style.NavTabContainer} count={3} />)}

      </div>
      {!scrollEnd && showScroll &&
      <IconButton tabindex="-1" className={style.rbutton} handleClick={() => slide(200)}>
        <KeyboardRightArrowIcon />
      </IconButton>}
      {signedIn ? (
        <div className={style.profile}>
          <AccountCircleIcon className={style.accountIcon} />
          <div className={style.accountWrapper}>
            <div className={style.account}>
              <span>{user.firstName}</span>
              <UserMenu firstName={user.firstName} />
            </div>
            <span className={style.appName}>Reporting and Analytics</span>
          </div>
        </div>
      ) : (
        <div className={style.profile}>&nbsp;</div>
      )}
    </header>
  );
}
