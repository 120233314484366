import { Help, Logout, Person } from '@mui/icons-material';

import { ReactComponent as AccountCirlce } from 'assets/icons/account-circle-white.svg';
import { ReactComponent as App } from 'assets/icons/apps.svg';
import { ReactComponent as HelpOutline } from 'assets/icons/help-outline.svg';
import { ReactComponent as KeyboardDownArrow } from 'assets/icons/keyboard-arrow-down.svg';
import { ReactComponent as KeyboardUpArrow } from 'assets/icons/keyboard-arrow-up.svg';
import { ReactComponent as KeyboardLeftArrow } from 'assets/icons/keyboard-arrow-left.svg';
import { ReactComponent as KeyboardRightArrow } from 'assets/icons/keyboard-arrow-right.svg';
import { ReactComponent as LogOut } from 'assets/icons/logout.svg';
import { ReactComponent as Print } from 'assets/icons/print.svg';
import { ReactComponent as PermIdentity } from 'assets/icons/perm-identity.svg';
import { ReactComponent as Sync } from 'assets/icons/sync.svg';
import { ReactComponent as WhiteLogo } from 'assets/icons/webmd-ignite-logo-reporting-analytics.svg';
import { ReactComponent as Feedback } from 'assets/icons/feedback.svg';
import { ReactComponent as CustomerService } from 'assets/icons/customer-service.svg';
import { ReactComponent as Tools } from 'assets/icons/mdi_tools.svg';

export const AccountCircleIcon = props => <AccountCirlce {...props} />;

export const AppIcon = props => <App {...props} />;

export const CustomerServiceIcon = props => <CustomerService {...props} />;

export const FeedbackIcon = props => <Feedback {...props} />;

export const HelpIcon = props => <Help {...props} />;

export const HelpOultineIcon = props => <HelpOutline {...props} />;

export const KeyboardDownArrowIcon = props => <KeyboardDownArrow {...props} />;

export const KeyboardUpArrowIcon = props => <KeyboardUpArrow {...props} />;

export const KeyboardLeftArrowIcon = props => <KeyboardLeftArrow {...props} />;

export const KeyboardRightArrowIcon = props => <KeyboardRightArrow {...props} />;

export const LogoutIcon = props => <Logout {...props} />;

export const LogOutOutlinedIcon = props => <LogOut {...props} />;

export const PersonIcon = props => <Person {...props} />;

export const LogOutIcon = props => <LogOut {...props} />;

export const PrintIcon = props => <Print {...props} />;

export const PermIdentityIcon = props => <PermIdentity {...props} />;

export const SyncIcon = props => <Sync {...props} />;

export const ToolsIcon = props => <Tools {...props} />;

export const WhiteLogoIcon = props => <WhiteLogo {...props} />;