import React from 'react';

import style from './textLabel.module.scss';

const TextLabel = ({label}) => {
  return (
    <div className={style.textLabel}>
      {label}
    </div>
  );
};

export default TextLabel;