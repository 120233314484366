import React, { useEffect } from 'react';

const IdleTimeOutHandler = props => {
  let timer = undefined;
  const events = ['click', 'scroll', 'load', 'keydown'];

  const eventHandler = eventType => {
    localStorage.setItem('lastInteractionTime', Date.now());
    if (timer) {
      startTimer();
    }
  };

  useEffect(() => {
    addEvents();

    return () => {
      removeEvents();
    };
  }, []);

  const startTimer = () => {
    timer = setTimeout(() => {
      const diff = Date.now() - localStorage.getItem('lastInteractionTime');
      let timeOutInterval = 1000 * 60 * 15;
      if (diff < timeOutInterval) {
        startTimer();
      } else {
        props.auth0State.logout();
      }
    }, 1000 * 60 * 15);
  };

  const addEvents = () => {
    events.forEach(eventName => {
      window.addEventListener(eventName, eventHandler);
    });
    startTimer();
  };

  const removeEvents = () => {
    events.forEach(eventName => {
      window.removeEventListener(eventName, eventHandler);
    });
  };

  return <div></div>;
};

export default IdleTimeOutHandler;