import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { BrowserRouter as Router, Route, matchPath } from "react-router-dom";

import { PostLoginRedirector } from "contexts/auth0Context";
import ProgressIndicator from 'components/progressIndicator/progressIndicator';
import { ProtectedRoute } from "containers/authentication/protectedRoute/protectedRoute";
import { AuthProvider } from 'contexts/authentication/authContext';
import ErrorBoundaryFallback from 'components/errors/errorBoundaryFallback/errorBoundaryFallback';
import { useAuth0Data } from "contexts/auth0Context";
import SecurityBoundary from 'containers/authentication/securityBoundary/securityBoundary';

const Auth0App = () => {
    const auth0Data = useAuth0Data();
    const [organizationAlias, setOrganizationAlias] = React.useState();

    if (!auth0Data || auth0Data.isLoading) return <ProgressIndicator />;

    return (
        <Auth0Provider {...auth0Data.auth0Config}>
        <Router basename={ organizationAlias }>
            <AuthProvider>
            <PostLoginRedirector setOrgAlias={setOrganizationAlias} />
            <Route path="*" render={props => {
                const errorMatch = matchPath(props.location.pathname + props.location.search, {
                path: "/?error=:error&error_description=:error_description&:others"
                });

                if (errorMatch) {
                const errorMessage = decodeURI(errorMatch.params.error);
                const errorDescription = decodeURI(errorMatch.params.error_description);

                if (errorMessage.toLowerCase() === "unauthorized" || errorDescription.toLowerCase() === "user is blocked") {
                    return (<ErrorBoundaryFallback error={ new Error("Unable to sign in") } />);
                }

                return (<ErrorBoundaryFallback error={ new Error(`${errorMessage}, ${errorDescription}`) } />);
                }

                return (<ProtectedRoute component={SecurityBoundary} />)
            }}>
            </Route>
            </AuthProvider>
        </Router>
        </Auth0Provider>
    );
}

export default Auth0App;