/* USER-FACING STRINGS (TODO: SUPPORT LOCALIZATION) */
export const PRODUCT_NAME = 'Healthwise Reporting and Analytics';
export const ERROR_MESSAGE_PREAMBLE = `We're sorry, ${PRODUCT_NAME} has encountered an error`;
export const ERROR_MESSAGE_SUPPORT = 'Please try again in a moment, or contact your administrator.';

/* SERVICE CALL ERROR MESSAGE */
export const ERROR_MESSAGE_BAD_REQUEST = 'Bad Request';
export const ERROR_MESSAGE_UNAUTHORIZED = 'Unauthorized';
export const ERROR_MESSAGE_INTERNAL_SERVER_ERROR = 'Internal Server Error';
export const ERROR_MESSAGE_NETWORK_ERROR = 'Network Error';
export const ERROR_MESSAGE_UNKNOWN_ERROR = 'Unknown Error';
export const ERROR_MESSAGE_NOT_FOUND = 'Not Found';
export const ERROR_MESSAGE_USER_ALREADY_EXISTS = 'User already exists';

/* Privacy Policy URL */
export const PRIVACY_POLICY_TERMS_TOOLTIP_TEXT = 'You are leaving this website for information purposes only.'
export const PRIVACY_POLICY_URL = 'https://www.healthwise.org/specialpages/legal/privacy.aspx';
export const TERMS_OF_USE_URL = 'https://www.healthwise.org/specialpages/legal/terms.aspx';

export * from "utils/dictionary/env";
