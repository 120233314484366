import * as EnvDictionary from 'utils/dictionary/env';
/* LINK PATHS within the UI application */
export const HOME_PATH = '/';
export const LOGIN_PATH = 'login';
export const LOGOUT_PATH = 'logout';

/* REPORT PATHS */
export const SUBSCRIPTIONS_PATH = 'subscriptions';
export const SUBSCRIPTIONS_SELECTED_PATH = 'subscriptions/:subscriptionId';
export const REPORT_SELECTED_PATH = 'subscriptions/:subscriptionId/reports/:reportId';
export const PAGE_SELECTED_PATH = 'subscriptions/:subscriptionId/reports/:reportId/pages/:pageId';

export const MYHEALTHWISE = EnvDictionary.REACT_APP_MYHEALTHWISE_ISSUER;
export const HELP_URL = EnvDictionary.REACT_APP_HELP_URL;

/* RESOURCE PATHS for calling the user management service */
