import { useContext, useState, useEffect } from 'react';
import { AuthContext } from 'contexts/authentication/authContext';
import {
  ERROR_MESSAGE_UNAUTHORIZED,
  ERROR_MESSAGE_INTERNAL_SERVER_ERROR,
  ERROR_MESSAGE_NETWORK_ERROR,
  ERROR_MESSAGE_NOT_FOUND,
} from 'utils/dictionary';
import { getSubscriptions } from 'utils/api/subscriptions';

// TODO: Pull out into common util
const setDataOrErrorMessage = (
  response,
  data,
  error,
  setData,
  setErrorMessage,
  setFinished,
) => {
  switch (response?.status) {
    case 200:
      setData(data);
      break;
    case 404:
      setErrorMessage(ERROR_MESSAGE_NOT_FOUND);
      break;
    case 401:
      setErrorMessage(ERROR_MESSAGE_UNAUTHORIZED);
      break;
    case 500:
      setErrorMessage(ERROR_MESSAGE_INTERNAL_SERVER_ERROR);
      break;
    default:
      error
        ? setErrorMessage(ERROR_MESSAGE_NETWORK_ERROR)
        : setErrorMessage(null);
      break;
  }
  setFinished(true);
};

/**
 * Retrieve the subscriptions
 */
export const useGetSubscriptions = () => {
  const { accessToken, loading } = useContext(AuthContext);

  const [data, setData] = useState([]);
  const [errorMessageSubs, setErrorMessage] = useState('');
  const [finished, setFinished] = useState(false);

  useEffect(() => {
    const response = async () => {
      const { response, data, error } = await getSubscriptions(
        accessToken,
      );

      setDataOrErrorMessage(
        response,
        data,
        error,
        setData,
        setErrorMessage,
        setFinished,
      );
    };

    if (!loading) {
      response();
    }
  }, [accessToken, loading]);

  return {
    data,
    errorMessageSubs,
    finished,
  };
};