import * as configuration from 'utils/configuration/subscriptions';
import * as utils from 'utils/api/common/invocationUtils';
import * as EnvDictionary from 'utils/dictionary/env';

const baseUrl = EnvDictionary.REACT_APP_REPORTING_API_BASE_URL;
const provisioningApiBaseUrl = EnvDictionary.REACT_APP_PROVISIONING_MANAGER_API_BASE_URL;

const reportsUrl = baseUrl + configuration.SUBSCRIPTIONS_ROOT;

export async function getSubscriptions(accessToken = null) {
  return await utils.getResource(
    `${reportsUrl}?${configuration.SUBSCRIPTIONS_VERSION}`,
    accessToken
  )
}

/**
 * Retrieve all of subscriptions for the user represented by the accessToken from provisioning service.
 */
export async function getAllUserSubscriptions(accessToken) {
  return await utils.getResource(`${provisioningApiBaseUrl}/auth/subscriptions/all?api-version=1.0`, accessToken);
}