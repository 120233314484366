import React from 'react';
import PropTypes from 'prop-types';
import { ERROR_MESSAGE_PREAMBLE, ERROR_MESSAGE_SUPPORT } from 'utils/dictionary';

const cardStyles = {
  width: '400px',
  maxWidth: '400px',
  marginLeft: 'auto',
  marginRight: 'auto',
};

const ErrorMessage = ({ errors, children }) => {
  const hasErrors = errors?.length > 0;

  return (
    <div style={cardStyles} title="Error">
      <div type="error" showIcon={false}>
        <p>
          {ERROR_MESSAGE_PREAMBLE}
          {hasErrors ? ':' : '.'}
        </p>

        {hasErrors ? (
          <ul>
            {errors.map(message => (
              <li key={message}>{message}</li>
            ))}
          </ul>
        ) : null}
        <p>{ERROR_MESSAGE_SUPPORT}</p>
      </div>
      {children}
    </div>
  );
};

ErrorMessage.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node,
};

ErrorMessage.defaultProps = {
  errors: [],
};

export default ErrorMessage;
