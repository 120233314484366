import React from "react";
import "./App.css";
import * as EnvDictionary from 'utils/dictionary/env';
import { Auth0DataProvider } from "contexts/auth0Context";
import Auth0App from "containers/auth0App/auth0App"; 

function App() {
  return (
    <Auth0DataProvider
      domain={EnvDictionary.REACT_APP_AUTH0_DOMAIN}
      clientId={EnvDictionary.REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      audience={EnvDictionary.REACT_APP_AUTH0_AUDIENCE}
      scope={EnvDictionary.REACT_APP_AUTH0_SCOPE}
      myHealthwiseBaseUrl={EnvDictionary.REACT_APP_MYHEALTHWISE_ISSUER}>
      <Auth0App />
    </Auth0DataProvider>
  );
}

export default App;
