import { useQuery } from 'react-query';
import { useErrorHandler } from 'react-error-boundary';

import { getResource } from 'utils/api/common/invocationUtils';
import * as configuration from 'utils/dictionary/profile.js';

import { Auth0State, Auth0Jwt } from 'state';

/**
 *  @typedef {Object} Query
 *  @property {Object} userProfile - The user's profile
 *  @property {boolean} isLoading - true if the query is still executing
 *  @property {Object} status - the status of the profile query
 */

/**
 * Lookup and retrieve the current user
 * @param {string} accessToken A valid access token
 */
// TODO - Move this function into utils/api/common/profile.js
export async function getUserProfile(accessToken) {
  let response = await getResource(`${configuration.profileUrl}`, accessToken);
  return response;
}

/**
 * Retrieve the user's profile
 *
 * @return {Query} The user's profile and query status
 */
export function useProfile() {
  const accessToken = Auth0Jwt();
  const authState = Auth0State();
  useErrorHandler(authState?.error);

  const { data, error, status } = useQuery(
    'userProfile',
    async () => {
      const data = await getUserProfile(accessToken);
      return data;
    },
    { enabled: !!accessToken },
  );

  return { data, error, status };
}