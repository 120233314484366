import env from '@beam-australia/react-env';

export const REACT_APP_SERVICE_REQUEST_URL = env('SERVICE_REQUEST_URL');
export const REACT_APP_PROVISIONING_MANAGER_API_BASE_URL = env('ProvisioningServiceUrl');
export const REACT_APP_OKTA_CLIENT_ID = env('OKTA_CLIENT_ID');
export const REACT_APP_OKTA_ISSUER = env('OKTA_ISSUER');
export const REACT_APP_REPORTING_API_BASE_URL = env('REPORTING_API_BASE_URL');
export const REACT_APP_MYHEALTHWISE_ISSUER = env('MYHEALTHWISE_ISSUER');
export const REACT_APP_ENVIRONMENT = env('ENVIRONMENT');
export const REACT_APP_HELP_URL = env('HELP_URL');
export const REACT_APP_AUTH0_DOMAIN = env('AUTH0_DOMAIN');
export const REACT_APP_AUTH0_CLIENT_ID = env('AUTH0_CLIENT_ID');
export const REACT_APP_AUTH0_AUDIENCE = env('AUTH0_AUDIENCE');
export const REACT_APP_AUTH0_SCOPE = env('AUTH0_SCOPE');
