import React from 'react';
import { useLocation } from 'react-router-dom';
import { Auth0IdTokenClaims, Auth0Jwt, Auth0State } from 'state/index';

import { LOGOUT_PATH, MYHEALTHWISE } from 'utils/configuration/links';
import { CallbackConfig } from 'configuration/auth0';

export const AuthContext = React.createContext();

function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );
  return JSON.parse(jsonPayload);
}

const getSubscriptionIdFromToken = token => {
  return parseJwt(token).subscriptionId;
};

const getOrgIdFromToken = token => {
  return parseJwt(token).organizationId;
};

const getOrganizationAlias = token => {
  return parseJwt(token).tempOrganizationAlias;
};

const customAuthHandler = async (auth0State, locationSearch) => {
  const { loginWithRedirect } = auth0State;

  await loginWithRedirect({
    authorizationParams: {
      redirect_url: CallbackConfig.frontDoorUri
    }
  });
};

export const AuthProvider = ({ children }) => {
  const [state, setState] = React.useReducer((oldState, newState) => newState, {
    loading: true,
    signedIn: false,
    token: undefined,
    permissions: null,
    user: null,
    managementRoles: [],
    getOrganizationAlias: null,
    customAuthHandler,
  });

  const updateAuth = async (authState, jwt, idTokenClaims) => {
    const token = idTokenClaims?.__raw;
    const accessToken = jwt;

    if (token && token !== state.token && accessToken !== state.accessToken) {
      try {
        const tokenParsed = parseJwt(token);
        const { firstName, lastName } = parseJwt(accessToken);

        setState({
          token,
          organizationId: getOrgIdFromToken(accessToken),
          subscriptionId: getSubscriptionIdFromToken(accessToken),
          accessToken: accessToken, // Added, previously when destructuring returned null
          permissions: parseJwt(accessToken),
          loading: false,
          signedIn: authState?.isAuthenticated || false,
          user: { ...tokenParsed, firstName, lastName },
          organizationAlias: getOrganizationAlias(accessToken),
          customAuthHandler,
        });
      } catch (error) {
        // TODO Render error to user
        // console.log('Issue parsing token', error);
      }
    }
  };

  return (
    <AuthContext.Provider value={{ ...state, updateAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export const AuthHandler = () => {
  const { updateAuth, customAuthHandler, organizationAlias } = React.useContext(AuthContext);
  const location = useLocation();
  const auth0State = Auth0State();
  const jwt = Auth0Jwt();
  const idTokenClaims = Auth0IdTokenClaims();

  React.useEffect(() => {
    updateAuth(auth0State, jwt, idTokenClaims);
  });

  React.useEffect(() => {
    if (location.pathname.includes(LOGOUT_PATH)) {
      window.location.replace(`${MYHEALTHWISE}/${organizationAlias}/signout`);
      sessionStorage.removeItem('features');
    }
  }, [
    auth0State,
    location.pathname,
    customAuthHandler,
    location.search,
  ]);

  return null;
};

export const AuthConsumer = AuthContext.Consumer;
