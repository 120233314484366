import * as EnvDictionary from 'utils/dictionary/env';

// What this block of variables does is create the url to call for myprofile operations.
// e.g. https://manage.healthwise.net/myprofile?api-version=1.0
const controllerName = 'myprofile';
const baseUrl = `${EnvDictionary.REACT_APP_PROVISIONING_MANAGER_API_BASE_URL}/${controllerName}`;
const MYPROFILE_API_VERSION = '1.0';
const baseQueryString = `?api-version=${MYPROFILE_API_VERSION}`;

// URLs for the user information
export const profileUrl = `${baseUrl}${baseQueryString}`;