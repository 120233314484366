import { useContext, useState, useEffect } from 'react';
import { AuthContext } from 'contexts/authentication/authContext';
import {
  ERROR_MESSAGE_UNAUTHORIZED,
  ERROR_MESSAGE_INTERNAL_SERVER_ERROR,
  ERROR_MESSAGE_NETWORK_ERROR,
  ERROR_MESSAGE_NOT_FOUND,
} from 'utils/dictionary';
import { getReports, getReportEmbed } from 'utils/api/reports';

// TODO: Pull out into common util
const setDataOrErrorMessage = (
  response,
  data,
  error,
  setData,
  setErrorMessage,
  setFinished,
) => {
  switch (response?.status) {
    case 200:
      setData(data);
      break;
    case 404:
      setErrorMessage(ERROR_MESSAGE_NOT_FOUND);
      break;
    case 401:
      setErrorMessage(ERROR_MESSAGE_UNAUTHORIZED);
      break;
    case 500:
      setErrorMessage(ERROR_MESSAGE_INTERNAL_SERVER_ERROR);
      break;
    default:
      error
        ? setErrorMessage(ERROR_MESSAGE_NETWORK_ERROR)
        : setErrorMessage(null);
      break;
  }
  setFinished(true);
};

/**
 * Retrieve the report list
 */
export const useGetReports = () => {
  const { accessToken, loading } = useContext(AuthContext);

  const [data, setData] = useState([]);
  const [errorMessageReports, setErrorMessage] = useState('');
  const [finished, setFinished] = useState(false);

  useEffect(() => {
    const response = async () => {
      const { response, data, error } = await getReports(
        accessToken,
      );

      setDataOrErrorMessage(
        response,
        data,
        error,
        setData,
        setErrorMessage,
        setFinished,
      );
    };

    if (!loading) {
      response();
    }
  }, [accessToken, loading]);

  return {
    data,
    errorMessageReports,
    finished,
  };
};

/**
 * Retrieve the embed token for the specified report
 */
 export const useGetReportEmbed = (reportId, subscriptionId) => {
  const { accessToken, loading } = useContext(AuthContext);

  const [data, setData] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [finished, setFinished] = useState(false);

  useEffect(() => {
    const response = async () => {
      const { response, data, error } = await getReportEmbed(
        reportId,
        subscriptionId,
        accessToken,
      );

      setDataOrErrorMessage(
        response,
        data,
        error,
        setData,
        setErrorMessage,
        setFinished,
      );
    };

    if (!loading) {
      response();
    }
  }, [reportId, accessToken, loading, subscriptionId]);

  return {
    data,
    errorMessage,
    finished,
  };
};
