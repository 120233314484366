import { useContext, useState, useEffect, createContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";
import {
    LOGIN_PATH,
    LOGOUT_PATH,
    SUBSCRIPTIONS_PATH,
    REPORT_SELECTED_PATH,
    PAGE_SELECTED_PATH
} from "utils/configuration/links";

const filterRoutes = [
    LOGIN_PATH,
    LOGOUT_PATH,
    SUBSCRIPTIONS_PATH,
    REPORT_SELECTED_PATH,
    PAGE_SELECTED_PATH
];

const Context = createContext();

export const Auth0DataProvider = (props) => {
    const {audience, scope, redirectUri, clientId, domain, myHealthwiseBaseUrl, children} = props;
    const { isAuthenticated } = useAuth0();
    const [auth0Data, setAuth0Data] = useState();
    const [organizationAlias, setOrganizationAlias] = useState();

    const getOrgId = async (alias) => {
        var requestHeaders = {
            Accept: 'application/json'
        };
        var request = new Request(`${myHealthwiseBaseUrl}/organization?organizationName=${alias}`, {method: 'get', headers: new Headers(requestHeaders)});
        var response = await fetch(request);
        const data = await response.json();

        if (!data.organizationId){
            throw new Error(`Organization id not found ${alias ? `for alias: ${alias}.` : "."}`)
        }
        return data.organizationId;
    };

    const configureAuth0 = (orgId) => {
        const authParams = {
            audience: audience,
            redirect_uri: redirectUri,
            scope: scope,
        };

        if (orgId)
            authParams.organization = orgId;

        const providerConfig = {
            domain: domain,
            clientId: clientId,
            authorizationParams: authParams
        };
        return providerConfig;
    };

    useEffect(() => {
        if (!isAuthenticated) {
            let alias = window.location.pathname.split('/')
                .filter(o => o.length) // Filter out empty elements
                .filter(o => !filterRoutes.includes(o))[0]; // Filter out any application routes
            
            /**
             * If there IS a organization alias, we need to look up the organization Id because we
             * are not authenticated AND the user is trying to enter using an organization alias
             */
            if (alias) {
                (async() => {
                    const orgId = await getOrgId(alias);
                    const config = configureAuth0(orgId);
                    // This is the main configuration needed to start the authentication process.
                    // Here the organizationAlias is null because it will be set after authentication
                    // happens
                    setAuth0Data({
                        auth0Config: config,
                        organizationAlias: null,
                        isLoading: false
                    });
                })();
                
                setOrganizationAlias(alias);
            }
            else {
               /**
                * If there is NOT an organization alias, the user should be prompted to enter one.
                */ 
               const config = configureAuth0(null);
               setAuth0Data({
                   auth0Config: config,
                   organizationAlias: null,
                   isLoading: false
               });
            }
        }
        else {
            auth0Data.organizationAlias = organizationAlias;
            setAuth0Data(auth0Data);
        }
    }, [isAuthenticated]);

    return (
        <Context.Provider value={auth0Data}>
            {children}
        </Context.Provider>
    );
};

export const useAuth0Data = () => useContext(Context);

export const PostLoginRedirector = ({ setOrgAlias }) => {
    const { isLoading, getAccessTokenSilently, isAuthenticated } = useAuth0();
    const history = useHistory();

    useEffect(() => {
        const setToken = async () => {
            const token = await getAccessTokenSilently();
            const decoded = jwt_decode(token);
            const baseRoute = decoded.tempOrganizationAlias;

            setOrgAlias(baseRoute);

            // If there is already a org alias on the route, set it to absolute pat
            const alias = window.location.pathname.split('/')
                .filter(o => o.length)
                .filter(o => !filterRoutes.includes(o))[0];
            if (!alias || baseRoute !== alias) {
                history.push({
                    pathname: baseRoute,
                    search: window.location.search
                });
            }
        };

        if (isAuthenticated)
            setToken();
    }, [isAuthenticated, isLoading]);

    return null;
};