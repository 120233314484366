import React from 'react'

const useMenuManageFocus = ( dataSize, liArrayRef, navRef, btnRef ) => {
    const [visible, setVisible] = React.useState(false);

    React.useEffect(()=>{
        let num = 0;
        const controller = new AbortController;

        if(visible){
            liArrayRef.current[num].focus();
        }
    
        navRef.current?.addEventListener("keydown", (event)=> {
          // 27 === esc key
          if(event.keyCode === 27 && visible){
            event.preventDefault();
            setVisible(false);
            btnRef.current.focus();
          }
        }, { signal: controller.signal })
    
        navRef.current?.addEventListener("keydown", (event)=> {
          // 40 === down arrow key
          if(event.keyCode === 40 && num < dataSize - 1){
            event.preventDefault();
              num++;   
            liArrayRef.current[num].focus()
          }
          // 38 === up arrow key
          if(event.keyCode === 38 && num > 0){
            event.preventDefault();
              num--;
            liArrayRef.current[num].focus()
          }
        }, { signal: controller.signal })
    
        return () => {
          controller.abort();
        }
      },[dataSize, liArrayRef, navRef, btnRef, visible])
  return {visible, setVisible};
}

export default useMenuManageFocus