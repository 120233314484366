import React from 'react';
import style from './progressIndicator.module.scss'

function ProgressIndicator() {

  return (
    <section className={style.circularProgressContainer}>
        <div className={style.circularProgress}></div>
    </section>
  )
}

export default ProgressIndicator