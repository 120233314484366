import React from 'react';
import { PRIVACY_POLICY_URL, TERMS_OF_USE_URL, PRIVACY_POLICY_TERMS_TOOLTIP_TEXT } from 'utils/dictionary';
import Tooltip from 'components/tooltip/tooltip';
import style from './footer.module.scss';

export const Footer = () => {
    const bulletSymbol = '\u2022';
    const switchOverDate = new Date('2023-01-01T00:00:00-06:00');
    const currentYear = new Date();
    let displayYear =
        currentYear >= switchOverDate
            ? `2022-${currentYear.getFullYear()}`
            : '2022';
  return (
          <div className={style.footer} >
              <span className={style.copyright}>
                  © {displayYear} Healthwise, Incorporated. Healthwise, Healthwise for every health decision, and the Healthwise logo are trademarks of Healthwise, Incorporated.  |{' '}
                  <Tooltip title={PRIVACY_POLICY_TERMS_TOOLTIP_TEXT}>
                      <a
                          aria-label="Privacy Policy, you are leaving this website for information purposes only."
                          href={PRIVACY_POLICY_URL}
                          target="_blank"
                          rel="noreferrer"
                          className={style.privacyLink}
                          
                      >
                      Privacy Notice
                     </a>
                  </Tooltip>
                  <p className={style.bullet} aria-hidden="true">{`${bulletSymbol}`}</p>
                  <Tooltip title={PRIVACY_POLICY_TERMS_TOOLTIP_TEXT}>
                      <a
                          aria-label="Terms of Use, you are leaving this website for information purposes only."
                          href={TERMS_OF_USE_URL}
                          target="_blank"
                          rel="noreferrer" 
                          className={style.privacyLink}
                      >
                          Terms of Use
                      </a>
                  </Tooltip>
                  </span>
        </div>
  );
};

export default Footer;
