import * as configuration from 'utils/configuration/reports';
import * as utils from 'utils/api/common/invocationUtils';
import * as EnvDictionary from 'utils/dictionary/env';

const baseUrl = EnvDictionary.REACT_APP_REPORTING_API_BASE_URL;

const reportsUrl = baseUrl + configuration.REPORTS_ROOT;

export async function getReports(accessToken = null) {
  return await utils.getResource(
    `${reportsUrl}?${configuration.REPORTS_VERSION}`,
    accessToken
  )
}

export async function getReportEmbed(reportId, subscriptionId, accessToken = null) {
  return await utils.getResource(
    `${reportsUrl}/${reportId}/embed?subscriptionId=${subscriptionId}&${configuration.REPORTS_VERSION}`,
    accessToken
  )
}