/* eslint-disable */
import { useEffect, useState } from "react";
import { useProfile } from "./useProfile";
import { useManagementSubscriptionSettings } from "./useManagementSubscriptionSettings";

export const useGetProfileMenuPermissions = () => {
    const [showContentFeedback, setShowContentFeedback] = useState(false);
    const [showLogServiceRequest, setShowLogServiceRequest] = useState(false);
    const { data: profileResponse } = useProfile();
    const profile = profileResponse?.data;
    const { subscriptionSettings } = useManagementSubscriptionSettings();

    useEffect(() => {
        if (profile && subscriptionSettings) {
            if (profile.contentFeedback !== showContentFeedback) {
                const enabledForSubscription = subscriptionSettings
                    .some(opt => (opt.name === 'Content Feedback' && opt.option.optionValue === 'true'));
                setShowContentFeedback((profile.contentFeedback && enabledForSubscription));
            }

            if (profile.logService !== showLogServiceRequest) {
                setShowLogServiceRequest(profile.logService);
            }
        }
    }, [profile, showContentFeedback, showLogServiceRequest, window.location.search, subscriptionSettings]);

    return {
        showContentFeedback,
        showLogServiceRequest
    };
}