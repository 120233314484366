/* global powerbi */
import React from 'react';
import { NavLink, useLocation, matchPath, useHistory, generatePath } from 'react-router-dom';
import { AuthContext } from 'contexts/authentication/authContext';

import SkeletonWithDelay from 'components/skeletonWithDelay/skeletonWithDelay';
import SubscriptionDropdown from 'components/sideNav/subscriptionDropdown/subscriptionDropdown';
import TextLabel from 'components/sideNav/textLabel/textLabel';
import { IconTextButton } from 'components/buttons/buttons';
import { PrintIcon } from 'components/icons/icons';
import { SUBSCRIPTIONS_SELECTED_PATH, REPORT_SELECTED_PATH, PAGE_SELECTED_PATH, HOME_PATH } from 'utils/configuration/links';

import style from './sideNav.module.scss';

const LastVisitedSubscriptionKey = "last-visited-subscription";

export default function SideNav({ className, reportRendered, reportContainerRef, reports, subscriptions }) {
  const location = useLocation();
  const history = useHistory();
  const { organizationAlias } = React.useContext(AuthContext);
  const {data: reportsData, finished: reportsFinished} = reports;
  const {data: subscriptionsData, finished: subscriptionsFinished} = subscriptions;
  const [pagesFromContainer, setPagesFromContainer] = React.useState([]);

  React.useEffect(() => {
    if(reportContainerRef && reportContainerRef.current) {
      const match = matchPath(location.pathname, {
        path: `/${organizationAlias}/${PAGE_SELECTED_PATH}`,
        exact: true,
        strict: false
      });

      if(match?.params.pageId) {
        const container = powerbi.get(reportContainerRef.current.firstElementChild);
        container.setPage(match?.params.pageId);
      }
    }
  }, [location.pathname, reportContainerRef, organizationAlias]);

  React.useEffect(() => {
    if(reportContainerRef && reportContainerRef.current) {
      const container = powerbi.get(reportContainerRef.current.firstElementChild);

      const getPagesFromContainer = async () => {
        setPagesFromContainer(await container.getPages());
      }

      getPagesFromContainer();
    }
  }, [reports, reportContainerRef, reportRendered]);

  const setSubscription = (subscriptionId, match) => {
    if(!subscriptionId) {
      history.push({
        pathname: HOME_PATH,
        search: window.location.search
      });
    } else {
      localStorage.setItem(LastVisitedSubscriptionKey, subscriptionId);
      
      if(!match || subscriptionId !== match.params.subscriptionId) {
        history.push({
          pathname: generatePath(`/${organizationAlias}/${SUBSCRIPTIONS_SELECTED_PATH}`, {subscriptionId: subscriptionId}),
          search: window.location.search
        });
      }
    }
  }

  const match = matchPath(location.pathname, {
    path: `/${organizationAlias}/${SUBSCRIPTIONS_SELECTED_PATH}`,
    exact: true,
    strict: false
  }) || matchPath(location.pathname, {
    path: `/${organizationAlias}/${REPORT_SELECTED_PATH}`,
    exact: true,
    strict: false
  }) || matchPath(location.pathname, {
    path: `/${organizationAlias}/${PAGE_SELECTED_PATH}`,
    exact: true,
    strict: false
  });

  if(subscriptionsFinished && subscriptionsData.length > 0) {
    if(match && match.params.subscriptionId) {
      // Look for a subscription in the subscription data that has a matching id to the route subscriptionId
      const subscriptionToFind = subscriptionsData.find(x => x.id === match.params.subscriptionId);

      setSubscription(subscriptionToFind?.id, match);
    } else {
      // No subscription was specified in the route, attempt to go to last visited sub or the first in the list
      const lastVisitedSubscriptionId = localStorage.getItem(LastVisitedSubscriptionKey);
      const lastVisitedSubscription = subscriptionsData.find(x => lastVisitedSubscriptionId && x.id === lastVisitedSubscriptionId);

      if(!lastVisitedSubscription) {
        localStorage.removeItem(LastVisitedSubscriptionKey);

        setSubscription(subscriptionsData[0].id, match);
      } else {
        setSubscription(lastVisitedSubscriptionId, match);
      }
    }
  }

  let pages = [];

  if(reportsFinished && reportsData.length > 0 && match && match.params.reportId) {
    // Look for a report in the report data that has a matching id to the route reportId
    const reportToFind = reportsData.find(x => x.id === match.params.reportId);
    if(reportToFind) {

      // Page visibility: 0 - Always Visible, 1 - Hidden in View Mode
      // See: https://learn.microsoft.com/en-us/javascript/api/powerbi/powerbi-client/page.page#properties

      pages = reportToFind.pages
        .filter(rp => pagesFromContainer.find(pfc => pfc.name === rp.name && pfc.visibility === 0))
        .sort((a, b) => {
          if(a.order > b.order) {
            return 1;
          } else if (b.order > a.order) {
            return -1;
          }

          return 0;
        });

      if(pages && pages.length && match.params.pageId) {
        const pageToFind = pages.find(x => x.name === match.params.pageId);
        if(!pageToFind) {
          history.push({
            pathname: generatePath(`/${organizationAlias}/${PAGE_SELECTED_PATH}`, {subscriptionId: match.params.subscriptionId, reportId: match.params.reportId, pageId: pages[0]?.name || ''}),
            search: window.location.search
          });
        }
      }
    }
  }
  
  const selectedSubscriptionId = match?.params.subscriptionId;
  const selectedReportId = match?.params.reportId;
  let heading = pages.filter(page => (page.name === match.params.pageId ? true : false))

  return (
    <section className={`${className} ${style.sideNavContainer}`}>
      <h1 className={style.hidden}>{heading[0]?.displayName}</h1> 
      <div>
        {subscriptionsData.length !== 1 ? (<TextLabel label="Subscription" />) : null}
        {subscriptionsFinished
        ? (<SubscriptionDropdown setSelectedSubscription={setSubscription} match={match} data={subscriptionsData} selectedSubscriptionId={selectedSubscriptionId} />)
        : (<SkeletonWithDelay className={style.navTab} containerClassName={style.NavTabContainer} count={1} />)}
        
        <TextLabel label="Data Visualizations" />
        {reportsFinished && pages.length > 0
        ? (<nav className={style.reportNav} aria-label="reports">
            <ul className={style.NavTabContainer}>
              {pages.map(tab => {
                return (
                  <li disabled={!selectedSubscriptionId} className={`${style.navTab}`} key={tab.name}>
                    <NavLink 
                      isActive={() => {
                        if(tab.name === match.params.pageId){
                          return true;
                        } else if(!match.params.pageId && pages[0].name===tab.name){
                          history.push({
                            pathname: generatePath(`/${organizationAlias}/${PAGE_SELECTED_PATH}`, {subscriptionId: match.params.subscriptionId, reportId: match.params.reportId, pageId: tab.name}),
                            search: window.location.search
                          });
                          return true;
                        }
                          return false;
                      }}
                      activeClassName={style.selectedReport} className={!selectedSubscriptionId ? style.disabledLink : ''} 
                      to={{
                        pathname: selectedSubscriptionId ? generatePath(`/${organizationAlias}/${PAGE_SELECTED_PATH}`, {subscriptionId: selectedSubscriptionId, reportId:selectedReportId, pageId: tab.name}) : '',
                        search: window.location.search
                      }}>
                      {tab.displayName}
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </nav>)
        : (<SkeletonWithDelay className={style.navTab} containerClassName={style.NavTabContainer} count={3} />)}
      </div>
      <div>
        <IconTextButton handleClick={() => {
        if(reportContainerRef.current?.firstElementChild) {
          const container = powerbi.get(reportContainerRef.current.firstElementChild);
          container.print();
        }
        }}
        disabled={match?.params.reportId ? false : true}
        text="Print Page"
        icon={<PrintIcon />}
        className={style.printButton}
        />
      </div>
    </section>
  )
}
