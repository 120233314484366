import React from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { v4 } from 'uuid';

import { useGetReportEmbed } from 'utils/hooks/useGetReports';

import style from './reportEmbed.module.scss';

const ReportEmbed = React.forwardRef((props, ref) => {
  const { setReportRendered, match: { params: { reportId, subscriptionId } } } = props;
  const { data: reportEmbed, errorMessage } = useGetReportEmbed(reportId, subscriptionId);

  if(!reportEmbed?.id) {
    return (<section className={style.loadingContainer}>
      {errorMessage 
      ? <p>There was an error embedding the report. Please refresh the page to try again or contact your Healthwise administrator.</p>
      : <p>Embedding report, please wait...</p>}
    </section>);
  }

  return (
  <section className={style.reportContainer} ref={ref}>
    <PowerBIEmbed
      embedConfig = {{
        type: 'report',   // Supported types: report, dashboard, tile, visual and qna
        id: reportEmbed.id,
        embedUrl:  reportEmbed.embedUrl,
        accessToken: reportEmbed.embedToken.token,
        tokenType: models.TokenType.Embed,
        permissions: models.Permissions.Read,
        settings: {
          panes: {
            pageNavigation: {
              visible: false
            },
            filters: {
              expanded: true,
              visible: true
            }
          },
          background: models.BackgroundType.Transparent,
        }
      }}

      eventHandlers = { 
        new Map([
          ['loaded', function () {
            console.log('Report loaded');
            setReportRendered(v4());
          }],
          ['rendered', function () {console.log('Report rendered');}],
          ['error', function (event) {console.log(event.detail);}]
        ])
      }
        
      cssClassName={style.report}

      getEmbeddedComponent = { (embeddedReport) => {
        embeddedReport.iframe.title = "Report"
      }}
    />
  </section>);
});

export default ReportEmbed;