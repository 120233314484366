import React, { useState, useEffect } from 'react';
import { IconButton } from 'components/buttons/buttons';
import { KeyboardDownArrowIcon, LogoutIcon, PersonIcon } from 'components/icons/icons';
import style from './userMenu.module.scss';
import { AuthContext } from 'contexts/authentication/authContext';
import { Auth0Jwt } from 'state';
import * as Links from 'utils/configuration/links';
import { HELP_URL } from 'utils/configuration/links';
import { useHistory } from 'react-router-dom';
import { AppIcon, HelpIcon, ToolsIcon, FeedbackIcon } from 'components/icons/icons';
import useMenuManageFocus from 'utils/hooks/useMenuManageFocus';
import { useGetProfileMenuPermissions } from 'utils/hooks/useGetProfileMenuPermissions';
import * as EnvDictionary from 'utils/dictionary/env';

const generateServiceRequestUrl = () => {
  const jwt = Auth0Jwt();
  return `${EnvDictionary.REACT_APP_SERVICE_REQUEST_URL}#${jwt}`;
}

export const ancestorHasId = (el, id) => {
  let parent = el;
  while (parent) {
    parent = parent.parentElement;
    if (parent && parent.id === id) {
      return parent;
    }
  }
  return undefined;
};

export default function UserMenu() {
  const {
      user: { firstName, lastName, email },
      organizationAlias
  } = React.useContext(AuthContext);
  const history = useHistory();
  const accessToken = Auth0Jwt();
  const helpUrl = HELP_URL + '#' + accessToken;
  const navRef = React.useRef(null);
  const liArrayRef = React.useRef([]);
  const btnRef = React.useRef(null);
  const firstMenuButton = React.useRef(null);
  const {visible, setVisible} = useMenuManageFocus(4, liArrayRef, navRef, btnRef);
  const [userMenuItems, setUserMenuItems] = useState();
  const { showContentFeedback, showLogServiceRequest } = useGetProfileMenuPermissions(); 
  const serviceRequestUrl = generateServiceRequestUrl();
  const contentFeedbackUrl = `${EnvDictionary.REACT_APP_MYHEALTHWISE_ISSUER}/${organizationAlias}/feedback${window.location.search}`;   
  const userInfoContainerId = 'userInfoContainer';
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleClick = (e) =>{
    e.stopPropagation();
    setVisible(!visible);
    setCurrentIndex(0);
    setTimeout(() => {
      if (visible && btnRef?.current) {
        btnRef.current.focus();
        removeClickHandler();
      } else {
        addClickHandler();
      }
    }, 50);
  }

  const clickListener = e => {
    const isNotControlsParent = !ancestorHasId(e.target, userInfoContainerId);
    if (isNotControlsParent) {
      setVisible(false);
      removeClickHandler();
    }
  };

  const removeClickHandler = () => {
    window.removeEventListener('click', clickListener);
  };

  const addClickHandler = () => {
    window.addEventListener('click', clickListener);
  };

  useEffect(() => {
    setUserMenuItems(document.querySelectorAll('[role="menuitem"]'));
  }, [visible]);

  useEffect(() => {
    if (firstMenuButton?.current && visible) {
      setTimeout(() => {
        firstMenuButton.current.focus();
      }, 50);
    }
  }, [firstMenuButton, visible]);

  var gotoIndex = function (idx) {
    if (idx === userMenuItems.length) {
      idx = 0;
    } else if (idx < 0) {
      idx = userMenuItems.length - 1;
    }

    userMenuItems[idx].focus();
    setCurrentIndex(idx);
  };

  var keys = {
    tab: 'Tab',
    enter: 'Enter',
    esc: 'Escape',
    space: 'Space',
    left: 'ArrowLeft',
    up: 'ArrowUp',
    right: 'ArrowRight',
    down: 'ArrowDown',
  };

  const keyPressEventListener = event => {
    var prevdef = true;
    switch (event.code) {
      case keys.tab:
        if (event.shiftKey) {
          gotoIndex(currentIndex - 1);
        } else {
          gotoIndex(currentIndex + 1);
        }
        prevdef = true;
        break;
      case keys.right:
        prevdef = true;
        break;
      case keys.left:
        prevdef = true;
        break;
      case keys.esc:
        setVisible(false);
        if (btnRef?.current) {
          btnRef.current.focus();
          removeClickHandler();
        }
        prevdef = true;
        break;
      case keys.down:
        gotoIndex(currentIndex + 1);
        prevdef = true;
        break;
      case keys.up:
        gotoIndex(currentIndex - 1);
        prevdef = true;
        break;
      case keys.enter:
      case keys.space:
        event.target.click();
        prevdef = false;
        break;
      default:
        prevdef = true;
        break;
    }

    if (prevdef) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const goToApplications = () => {
    window.location.assign(`${Links.MYHEALTHWISE}/${organizationAlias}/applications${window.location.search}`);
  }

  const goToHelp = () => {
    window.open(helpUrl, '_blank');
  }

  const goToProfile = () => {
    window.location.assign(`${Links.MYHEALTHWISE}/${organizationAlias}/profile${window.location.search}`);
  }

  const goToSignOut = () => {
    history.push(`/${organizationAlias}/${Links.LOGOUT_PATH}`);
  }

  const goToLogServiceRequest = () => {
    window.open(serviceRequestUrl, '_blank');
  }

  const goToContentFeedback = () => {
    window.location.assign(contentFeedbackUrl, '_blank');
  }

  useEffect(()=>{
    let iframeMouseOver = false
    const controller = new AbortController;
      
    const clickInIframe = (e) => {
      if(iframeMouseOver){
        setVisible(false); 
      }
    }

    const clickOutsideIframe = (e) => {
      if(!navRef.current.contains(e.target) && !navRef.current.contains(e.target)) {
        setVisible(false);
      }
    }
    window.addEventListener('mousedown', clickOutsideIframe, { signal: controller.signal });
    // e.target won't identify the iframe on mousedown event so blur, mouseover, and mouseout events are needed 
    window.addEventListener('blur', clickInIframe, { signal: controller.signal });
    document.getElementById('reportContainer').addEventListener('mouseover', function(){
      iframeMouseOver = true;
    }, { signal: controller.signal });
    document.getElementById('reportContainer').addEventListener('mouseout', function(){
        iframeMouseOver = false;
    }, { signal: controller.signal });
    return () => {
      controller.abort();
    }
  },[ visible, setVisible ]) 

  return (
  <>
    <IconButton
      ref={btnRef}
      ariaLabel={`${firstName} Account`}
      ariaControls="menu"
      ariaHaspopup="menu"
      ariaExpanded={visible}
      className={`${style.profileButton} ${ visible ? style.buttonActive : '' }`}
      handleClick={handleClick}>
      <KeyboardDownArrowIcon className={style.downArrowIcon} />
    </IconButton>
    <div className={`${style.userInfoContainer} ${visible ? "" : style.invisible}`}>
      <nav ref={navRef} className={style.nav}>
        <div className={style.header}>
          <p role="presentation" className={style.name}>{firstName} {lastName}</p>
          <p role="presentation" className={style.email}>{email}</p>
        </div>

        <div className={style.divider}></div>

        <ul role="menu" className={style.menu}>
          <li
            role="menuitem"
            className={style.myHealthwiseItem}
            onClick={goToApplications}
            onKeyDown={keyPressEventListener}
            tabIndex="0"
            ref={firstMenuButton}>
            <AppIcon className={style.icon} style={{ 'fill': 'black' }} />
            <span
              ref={(ref)=> liArrayRef.current.push(ref)} 
              className={style.link}
              aria-label='MyHealthwise - Link'>
              MyHealthwise
            </span>
          </li>
        </ul>

        <div className={style.divider}></div>

        <ul role="menu" className={style.menu}>
          <li
            role="menuitem"
            className={style.menuItem}
            onClick={goToProfile}
            onKeyDown={keyPressEventListener}
            tabIndex="0">
            <PersonIcon className={style.icon} />
            <span 
              ref={(ref)=> liArrayRef.current.push(ref)} 
              className={style.link}
              aria-label='My Profile - Link'>
              My Profile
            </span>
          </li>
          <li
            role="menuitem"
            className={style.menuItem}
            onClick={goToHelp}
            onKeyDown={keyPressEventListener}
            tabIndex="0">
            <HelpIcon className={style.icon} />
            <span
              ref={(ref)=> liArrayRef.current.push(ref)} 
              className={style.link}
              rel="noreferrer"
              aria-label='Get Help - Link - opens new tab'>
              Get Help
            </span>
          </li>
          {showLogServiceRequest ? (
            <li
              role="menuitem"
              className={style.menuItem}
              onClick={goToLogServiceRequest}
              onKeyDown={keyPressEventListener}
              tabIndex="0">
              <ToolsIcon className={style.icon} />
              <span
                ref={(ref)=> liArrayRef.current.push(ref)} 
                className={style.link}
                aria-label='Log Service Request - Link - opens new tab'>
                  Log Service Request
              </span>
            </li>
          ) : <li tabIndex="-1" className={style.menuLiHide}></li>}
          {showContentFeedback ? (
            <li
              role="menuitem"
              className={style.menuItem}
              onClick={goToContentFeedback}
              onKeyDown={keyPressEventListener}
              tabIndex="0">
              <FeedbackIcon className={style.icon} />
              <span 
                ref={(ref)=> liArrayRef.current.push(ref)}
                className={style.link}
                aria-label='Submit Content Feedback - Link'>
                Submit Content Feedback
              </span>
            </li>
          ) : <li tabIndex="-1" className={style.menuLiHide}></li>}
        </ul>

        <div className={style.divider} />

        <ul role="menu" className={style.menu}>
          <li
            role="menuitem"
            className={style.menuItem}
            onClick={goToSignOut}
            onKeyDown={keyPressEventListener}
            tabIndex="0">
            <LogoutIcon className={style.icon} />
            <span
              ref={(ref)=> liArrayRef.current.push(ref)}
              className={style.link}
              aria-label='Sign Out - Link'>
                Sign Out
            </span>
          </li>
        </ul>
      </nav>
    </div>
  </>);
}
