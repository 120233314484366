import React from 'react';

import Skeleton from 'react-loading-skeleton';
import style from './skeletonWithDelay.module.scss';
import 'react-loading-skeleton/dist/skeleton.css'

// Used to avoid flashing the skeleton if the loading is really fast,
// but show the skeleton if it takes a little longer
const SkeletonWithDelay = ({delayInMs = 2500, className, ...skeletonProps}) => {
	const [showLoadingIndicator, setLoadingIndicatorVisibility] = React.useState(false);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setLoadingIndicatorVisibility(true);
    }, delayInMs);
    return () => clearTimeout(timer);
  },[delayInMs]);

  return (<Skeleton {...skeletonProps} className={`${className} ${!showLoadingIndicator ? style.invisible : ''}`} />)
}

export default SkeletonWithDelay;