import React from 'react';
import PropTypes from 'prop-types';
import style from './buttons.module.scss';

export const IconButton = React.forwardRef(({ ariaLabel, className, handleClick, tabindex, ariaControls, ariaHaspopup, ariaExpanded, children }, ref)=>{
  return (
    <button
        aria-label={ariaLabel}
        className={`${style.iconButton} ${className}`}
        onClick={handleClick}
        tabIndex={tabindex}
        aria-controls={ariaControls}
        aria-haspopup={ariaHaspopup}
        aria-expanded={ariaExpanded}
        ref={ref}
    >
        {children}
    </button>
  )
})

export const IconTextButton =({ className, handleClick, icon, text, disabled}) => {
  return (
    <button
        text={text}
        onClick={handleClick}
        className={`${style.iconTextButton} ${className}`}
        disabled={disabled}
    >
        {icon} {text}
    </button>
  )
}

export const IconLinkButton =({ icon, href, ariaLabel, className }) => {
  return (
    <a
      href={href}
      aria-label={ariaLabel}
      className={className}
    >
      {icon}
    </a>
  )
}

IconButton.propTypes = {
    ariaLabel: PropTypes.string,
    className: PropTypes.string,
    handleClick: PropTypes.func.isRequired,
    children: PropTypes.any.isRequired,
}

IconTextButton.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
  icon: PropTypes.element.isRequired,
}