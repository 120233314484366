import { useState, useEffect } from 'react';
import { useGetAllUserSubscriptions } from 'utils/hooks/useGetAllUserSubscriptions';
import { getSubscriptionSettings } from 'utils/api/settings';
import { Auth0Jwt } from 'state';

export const useManagementSubscriptionSettings = () => {
    const [subscriptionSettings, setSubscriptionSettings] = useState([]);
    const { data: apps } = useGetAllUserSubscriptions();
    const jwt = Auth0Jwt();
    const subscriptions = apps?.map(app => app.subscriptions)
        .reduce((p, n) => p.id === n.id ? [...p] : [...p, ...n])
        .filter(s => s.name === "Management");

    useEffect(() => {
        const getSettings = async (subscriptionId, accessToken) => {
            const settings = await getSubscriptionSettings(
                subscriptionId,
                accessToken,
              );
      
            if(settings.data)
                setSubscriptionSettings(prevState => [...prevState, ...settings.data]);
        }
        if (subscriptions && subscriptions.length) {
            subscriptions.forEach(subscription => getSettings(subscription.id, jwt));
        }
    }, [apps, jwt]);

    return { subscriptionSettings };
};