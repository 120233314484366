import React, { useState } from "react";
import style from './tooltip.module.scss';

const Tooltip = (props) => {
    let timeout;
    const [active, setActive] = useState(false);

    const displayTooltip = () => {
        timeout = setTimeout(() => {
            setActive(true);
        }, props.delay || 0);
    };

    const hideTooltip = () => {
        clearInterval(timeout);
        setActive(false);
    };

    return (
        <div
            className={style.tooltipWrapper}
            onMouseEnter={displayTooltip}
            onMouseLeave={hideTooltip}
            onFocus={displayTooltip}
            onBlur={hideTooltip}
        >
            {props.children}
            {active && (<div className={style.tooltipTop}>
                {props.title}
            </div>
            )}
        </div>
    );
};

export default Tooltip;
