import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from 'react';

export const Auth0Jwt = () => {
    const authState = useAuth0();
    const [userJwt, setUserJwt] = useState('');
  
    useEffect(() => {
      const resolveIdTokenClaims = async () => {
        const token = await authState.getAccessTokenSilently();
        setUserJwt(token);
      };
  
      if (authState != null) {
        resolveIdTokenClaims();
      }
    }, [authState]);
  
    return userJwt;
  };

export const Auth0State = () => {
    return useAuth0();
};

export const Auth0IdTokenClaims = () => {
    const newAuthTokenClaimsState = useAuth0();
    const [userIdTokenClaims, setuserIdTokenClaims] = useState('');
    useEffect(() => {
        const resolveIdTokenClaims = async () => {
            const claims = await newAuthTokenClaimsState.getIdTokenClaims();
            setuserIdTokenClaims(claims);
        };

        if (newAuthTokenClaimsState != null) {
            resolveIdTokenClaims();
        }
    }, [newAuthTokenClaimsState]);

    return userIdTokenClaims;
};